@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "JUBILAT";
  src: url("./assets/fonts/JUBILAT-BOLD.woff");
}

@font-face {
  font-family: "Newmake";
  src: url("./assets/fonts/NewakeFont-Demo.otf");
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100vh;
  width: 100vw;
  background-color: #f4f4f4;
}

.blue-text:nth-child(1) {
  color: #0e0e9a;
}

.white-text {
  color: #ffffff !important;
}

#root {
  height: 100vh;
  width: 100vw;
}

#postcontainer .posts {
  display: flex;
  flex-direction: column;
}

#postcontainer .posts .post-title {
  flex: 1;
  margin-bottom: 10px;
}

#postcontainer .posts .post-title h2 {
  font-weight: 600 !important;
}

#postcontainer .posts .post-image img {
  width: 50%;
}

#newsnagivation li:first-child img,
#newsnagivation li:last-child img {
  margin-bottom: 1.5px;
}

#newsnavigation li:not(:first-child) {
  margin-left: 5px;
}

#newsnavigation li:not(:last-child) {
  margin-right: 5px;
}

.ease-linear {
  transition: all 0.25s ease;
}

.rotate-180 {
  transform: rotate(180deg);
}

.scale-150 {
  transform: scale(150%);
}

.power-alerts__shadow {
  /* box-shadow: 0px 4px 15px rgba(0, 80, 157, 0.15); */
  box-shadow: 0px 4px 15px rgba(0, 80, 157, 0.25);
}

.first-mb-3:first-of-type {
  margin-bottom: 12px;
}

.first-mb-0:first-of-type {
  margin-bottom: 0;
}

.business-hours-location:target {
  display: block;
  position: relative;
  top: -100px;
  /* top: 1000px; */
  visibility: hidden;
}

.container-1600px {
  max-width: 1600px;
  margin: 0 auto;
}

.search-box__container {
  position: relative;
}

.search-box__suggestion {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform: translateY(50px);
  padding-left: 5px;
  background-color: white;
}

.search-box__suggestion-item {
  height: 30px;
  display: flex;
  align-items: center;
  /* padding-top: 2px; */
}

.search-page__content {
  min-height: 50vh;
}

.navbar__linear-gradient {
  /* background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 81%,
    rgba(0, 0, 0, 0.1) 82%,
    rgba(0, 0, 0, 0.3) 90%,
    rgba(0, 0, 0, 0.5) 100%
  ); */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* .carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
  border-radius: 20px !important;
  overflow: hidden !important;
} */
.carousel-buttons {
  width: 14px !important;
  height: 14px !important;
  border: 0 !important;
  border-top: unset !important;
  border-bottom: unset !important;
  border-radius: 50px !important;
  border-collapse: separate !important;
  overflow: hidden !important;
}

.youtube-video__container {
  width: 100%;
  height: 100%;
  padding: 10px;
}

.youtube-video__container--2 {
  width: 100%;
  height: 100%;
  padding: 8px;
  background-color: white;
}

.youtube-video__iframe {
  width: 100%;
  height: 100%;
}

.thumbnail__linear-gradient {
  background: linear-gradient(
    180deg,
    rgba(1, 1, 1, 0) 60%,
    rgba(1, 1, 1, 1) 100%
  );
}

.cover__linear-gradient {
  background: linear-gradient(-90deg, #00519d2b 60%, #00509da6 100%);
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.see-watts-new__connect-with-us {
  top: 60%;
  left: 31%;
}
@media screen and (max-width: 800px) {
  .see-watts-new__connect-with-us {
    top: 65%;
  }
}

.aspect-ratio__16-5 {
  aspect-ratio: 16/5;
}
.aspect-ratio__16-6 {
  aspect-ratio: 16/6;
}

.carousel__height {
  height: 400px;
}

.carousel__height--2 {
  height: 530px;
}

@media screen and (max-width: 1000px) {
  .carousel__height {
    height: 600px;
  }

  .carousel__height--2 {
    height: 600px;
  }
}

.csd-home__linear-gradient {
  background: linear-gradient(90deg, #fff100 20%, #009654 100%);
}

.financial-statement-columns {
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
}

.financial-statement-columns {
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
}

.rate-schedules-container {
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
}

.anniv-carousel {
  background-color: transparent;
}

.guam-color {
  color: #f9f41f;
}

.underline-arc {
  position: relative;
}

.underline-arc::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: -110px;
  width: 340px;
  height: 100px;
  border: solid 5px #fede00;
  border-color: #fede00 transparent transparent transparent;
  border-radius: 50%/10px 10px 0 0;
}

.outages-underline-arc {
  position: relative;
}

.outages-underline-arc::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: -110px;
  width: 230px;
  height: 100px;
  border: solid 5px #fede00;
  border-color: #fede00 transparent transparent transparent;
  border-radius: 50%/10px 10px 0 0;
}

.with-outline {
  color: transparent;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: white;
}

@media only screen and (max-width: 800px) {
  .underline-arc::after {
    content: "";
    position: absolute;
    right: 10px;
    bottom: -110px;
    width: 210px;
    height: 100px;
    border: solid 5px #fede00;
    border-color: #fede00 transparent transparent transparent;
    border-radius: 50%/10px 10px 0 0;
  }

  .outages-underline-arc::after {
    content: "";
    position: absolute;
    right: 5px;
    bottom: -110px;
    width: 120px;
    height: 100px;
    border: solid 5px #fede00;
    border-color: #fede00 transparent transparent transparent;
    border-radius: 50%/10px 10px 0 0;
  }
}

@media only screen and (max-width: 584px) {
  .underline-arc::after {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .financial-statement-columns {
    column-count: 2;
  }
  .anniv-carousel {
    background-color: rgba(126, 126, 126, 0.445);
  }
}

@media only screen and (max-width: 570px) {
  .financial-statement-columns {
    column-count: 1;
  }
}

@media only screen and (max-width: 1500px) {
  .rate-schedules-container {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
  }
}

@keyframes buttonClosing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes buttonOpening {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0);
  }
}
